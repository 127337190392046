import pci from '../../assets/images/footer/pci.webp';
import master from '../../assets/images/footer/mastercard.webp';
import visa from '../../assets/images/footer/visa.webp';
import unicredit from '../../assets/images/footer/unicredit-bulbank.webp';
import secure from '../../assets/images/footer/secure.webp';
// import insta from '../../assets/images/footer/insta.webp';
// import linkin from '../../assets/images/footer/in.webp';
// import tube from '../../assets/images/footer/tube.webp';
// import fb from '../../assets/images/footer/fb.webp';
// import tiktok from '../../assets/images/footer/tiktok.webp';
import magnati from '../../assets/images/footer/magnati.webp';
import { BritainFlag, BulgariaFlag, RomaniaFlag, SaudiArabiaFlag, UaeFlag } from '../icons/icons';
import tube_blog from '../../assets/images/blog/tube.webp'
import tiktok_blog from '../../assets/images/blog/tiktok.webp'
import insta_blog from '../../assets/images/blog/inst.webp'
import fb_blog from '../../assets/images/blog/fb.webp'
import linkin_blog from '../../assets/images/blog/linkin.webp'

export const MAIN_URL = 'https://ro-sellavi-strapi.cabahub.com/api';
export const SELLAVI_API_URL = 'https://ro.sellavi.com'
export const PHOTO_URL = `https://ro-sellavi-strapi.cabahub.com/uploads`;

export const HEADING_BLOCK = 'heading';
export const PARAGRAPH_BLOCK = 'paragraph';
export const LIST_BLOCK = 'list';
export const ORDERED_LIST_FORMAT = 'ordered';
export const IMAGE_BLOCK = 'image';
export const QUOTE_BLOCK = 'quote';
export const CODE_BLOCK = 'code';
export const CHILD_TYPE_TEXT = 'text';
export const CHILD_TYPE_LINK = 'link';
export const CHILD_TYPE_LIST_ITEM = 'list-item';
export const TITLE_VAR = '{{TITLE}}';
export const GOOD_TITLE_VAR = '{{GOOD}}';
export const BAD_TITLE_VAR = '{{BAD}}';
export const PROMO_START_VAR = '{{PROMO_START}}';

export const CHAT_ID = '-1002217824187';
export const CHAT_ID_PARTNERS = '-1002181467555';
export const BOT_TOKEN = '6671687003:AAG-keX0Lch_FC_lduj4fzC6JBcyEOgL0KU';

export const CARDS_LIMIT = 9
export const productIdStorageKey = 'like'

export const EN_LANGUAGE = "EN";
export const RO_LANGUAGE = "RO";
export const LANGUAGES = {
    EN: {
        lang: "En",
        flag: BritainFlag("flag-icon"),
    },
    RO: {
        lang: 'Ro',
        flag: RomaniaFlag("flag-icon")
    }
};
export const VALIDE_LANG = ['EN', 'RO']
// export const FOOTER_LINKS = [
//     {
//         title: 'Price',
//         link: '/price'
//     },
//     {
//         title: 'Partners',
//         link: '/partners'
//     },
//     {
//         title: 'How to thrive in e-commerce',
//         link: '/blog'
//     },
// ]

export const ONLINE_STORE = 'Online store'
export const SMM = 'SMM'
export const STARTING_UP = 'Starting up'
export const E_COMMERCE = 'E-commerce'
export const TECH = 'Tech'
export const MARKETING_AND_SALES = 'Marketing & Sales'

export const SOCIAL_BLOG = [
    // {
    //     link: '',
    //     img: tube_blog,
    // },
    {
        link: 'https://www.instagram.com/sellavi.romania',
        img: insta_blog,
    },
    // {
    //     link: '',
    //     img: tiktok_blog,
    // },
    {
        link: 'https://www.facebook.com/profile.php?id=61558401566073',
        img: fb_blog,
    },
    {
        link: 'https://www.linkedin.com/company/sellavi-global/',
        img: linkin_blog,
    },
]

export const FOOTER_LINKS_COLUMN = [
    {
        title: 'Get to know us',
        name: 'column1',
        subtitle: [
            {
                title: 'About Us',
                link: '/about'
            },
            {
                title: 'Terms & Conditions',
                link: ''
            },
            {
                title: 'Privacy Policy',
                link: ''
            },
            {
                title: 'Affiliates Program',
                link: ''
            },
        ],
        link: ''
    },
    {
        title: 'Abilities',
        name: 'column2',
        subtitle: [
            {
                title: 'Aquiring',
                link: ''
            },
            {
                title: 'Design & Branding',
                link: ''
            },
            {
                title: 'Booking & Digital Products',
                link: ''
            },
            {
                title: 'API & Webhook',
                link: ''
            },
            {
                title: 'Demo Version',
                link: ''
            },
        ],
        link: ''
    },
    {
        title: 'Sales & Marketing',
        name: 'column3',
        subtitle: [
            {
                title: 'Instagram Shopping',
                link: ''
            },
            {
                title: 'Facebook',
                link: ''
            },
            {
                title: 'TikTok for Business',
                link: ''
            },
            {
                title: 'Google SEO',
                link: ''
            }
        ],
        link: ''
    },
    {
        title: 'Support',
        name: 'column4line1',
        subtitle: [
            {
                title: 'Help Center',
                link: ''
            },
        ],
        link: ''
    },
    {
        title: 'Contact info',
        name: 'column4line2',
        subtitle: [
            {
                title: 'info@sellavi.com',
                link: ''
            },
            // {
            //     title: 'support@sellavi.com',
            //     link: ''
            // },
        ],
        link: ''
    },
    {
        title: '+359 2 4927 777',
        name: 'column4line3',
        link: ''
    },
]

// export const FOOTER_NAV_LINKS = [
//     {
//         path: '/about',
//         title: 'About us'
//     },
//     {
//         path: '/prices',
//         title: 'Prices'
//     },
//     {
//         path: '/partners',
//         title: 'Affiliate program'
//     },
//     {
//         path: '/blog',
//         title: 'How to thrive in e-commerce',
//         mobileTitle: 'How to do business',
//     },
//     // {
//     //     path: '#',
//     //     title: 'Free trial'
//     // },
// ]

export const FOOTER_CONTACTS = [
    {
        title: 'info@sellavi.com',
        link: 'mailto:info@sellavi.com'
    },
    // {
    //     title: 'support@sellavi.com',
    //     link: 'mailto:support@sellavi.com'
    // },
]

export const FOOTER_LOGO_COLOR = [
    {
        name: 'master',
        logo: master,
    },
    {
        name: 'visa',
        logo: visa
    },
    {
        name: 'unicredit',
        logo: unicredit,
    },
    {
        name: 'secure',
        logo: secure
    },
    {
        name: 'pci',
        logo: pci
    },
]

// export const FOOTER_LOGO_SOCIAL = [
//     {
//         logo: insta,
//     },
//     {
//         logo: fb
//     },
//     {
//         logo: linkin
//     },
//     {
//         logo: tiktok
//     },
//     {
//         logo: tube
//     },
// ]

export const INFO_EMAIL = 'uae@sellavi.com'

export const THEME_LIGHT_TYPE = 'light'
export const THEME_DARK_TYPE = 'dark'

export const HEADER_LINKS = [
    // {
    //     path: '/',
    //     name: 'Home'
    // },
    {
        path: '/about',
        name: 'About us'
    },
    {
        path: '/prices',
        name: 'Prices'
    },
    {
        path: '/partners',
        name: 'Affiliate program'
    },
    {
        path: '/blog',
        name: 'How to thrive in e-commerce'
    },
]

export const MAIN_NAV_LINKS = [
    {
        path: 'start',
        name: 'Start trial'
    },
    {
        path: 'reviews',
        name: 'Customer reviews'
    },
    {
        path: 'steps',
        name: 'Setup steps'
    },
    {
        path: 'features',
        name: 'Features'
    },
    {
        path: 'ai',
        name: 'AI commerce'
    },
    {
        path: 'examples',
        name: 'Examples'
    },
    {
        path: 'benefits',
        name: 'Benefits'
    },
]

export const UAE_PHONE_CODE = { icon: UaeFlag, value: '+971' };
export const SAUDI_PHONE_CODE = { icon: SaudiArabiaFlag, value: '+966' };
export const ROMANIA_PHONE_CODE = { icon: RomaniaFlag, value: '+40' };
export const BULGARIA_PHONE_CODE = { icon: BulgariaFlag, value: '+359' };

export const PHONE_CODES_LIST = [
    ROMANIA_PHONE_CODE,
    UAE_PHONE_CODE,
    SAUDI_PHONE_CODE,
    BULGARIA_PHONE_CODE,
];

export const initialSignupValues = {
    code: ROMANIA_PHONE_CODE,
    email: '',
    phone: '',
    password: '',
};

export const initialSugnupValidity = {
    email: {},
    phone: {},
    password: {},
    name: {},
    business: {},
    occupation: {},
    confirm: {}
};